@import "../../variables.scss";
.secondSection {
	width: 100vw;
	padding-bottom: 80px;
	margin-top: calc((#{$firstSectionFloatDivHeight} / 2) + 30px);
	&-main {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		align-items: center;
		&-leftSide {
			> div {
				position: relative;
				height: 130px;
				width: 300px;
				color: #fff;
				display: flex;
				align-items: center;
				padding-left: 24px;
				&:hover {
					cursor: pointer;
				}
				> p {
					font-size: 24px;
				}
				> i {
					font-size: 50px;
					margin-right: 30px;
				}
				> div {
					&:last-of-type {
						position: absolute;
						width: 15px;
						height: 15px;
						right: -7.5px;
						top: 50%;
						transform: rotate(45deg);
					}
				}
				&:nth-child(1) {
					background-color: $light-blue;
					> div:last-of-type {
						background-color: $light-blue;
					}
				}
				&:nth-child(2) {
					background-color: #106489;
					> div:last-of-type {
						background-color: #106489;
					}
				}
				&:nth-child(3) {
					background-color: $dark-blue;
					> div:last-of-type {
						background-color: $dark-blue;
					}
				}
			}
		}
		&-rightSide {
			width: 100%;
			> div {
				padding: 20px 40px 20px 40px;
				background-color: #ececed;
				margin: 20px 0px;
				> div {
					height: 90px;
					padding: 10px;
					&:not(:first-of-type) {
						margin-top: 20px;
					}
					&:not(:last-of-type) {
						border-bottom: 1px solid #dadada;
					}
					> p {
						color: #383838;
						&:first-of-type {
							font-weight: bold;
							margin-bottom: 5px;
						}
					}
				}
			}
		}
	}
}


@media (max-width: 1366px){
	.secondSection {
		margin-top: 27vw;
	}
}

@media (max-width: 1366px){
	.secondSection {
		margin-top: 30vw;
	}
}

@media (max-width: 1200px) {
	.secondSection {
		&-main {
			width: 960px;
		}
	}
}

@media (min-width: 1024px) and (max-height:500px){
	.secondSection {
		margin-top: calc(#{$firstSectionFloatDivHeight} + 30px);
	}
}

@media (max-width: 960px) {
	.secondSection {
		padding-bottom: 0px;
		margin-top: 24px;
		&-main {
			width: 100%;
			display: block;
			&-leftSide {
				display: flex;
				> div {
					width: 33%;
					gap: 12px;
					padding-left: 12px;
					position: relative;
					> i {
						font-size: 32px;
						margin-right: 0px;
					}
					> p {
						font-size: 14px;
						font-weight: 500;
					}
					> div:last-of-type {
						top: initial;
						bottom: -7.5px;
						right: 50%;
					}
				}
			}
			&-rightSide {
				> div {
					> div {
						height: auto;
						> p {
							&:first-of-type {
								font-size: 18px;
							}
							&:last-of-type {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}
