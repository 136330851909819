@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("fonts/helvetica75.woff") format("woff");
//   font-weight: bold;
// }

// @font-face {
//   font-family: "Helvetica Neue";
//   src: url("fonts/helvetica55.woff") format("woff");
//   font-weight: normal;
// }

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Helvetica Neue";
  font-family: "Raleway", sans-serif;
}

html,
body {
  overflow: hidden auto;
}

body {
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 5px;
    scroll-behavior: smooth;
    background-color: #fff;
  }
  &::-webkit-scrollbar-track {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #dcdcdc;
  }
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
