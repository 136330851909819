$navbar-height: 60px;
$light-blue: #0176a6;
$medium-blue: #01638c;
$gray: #40484b;
$light: #53efe0;
$dark-blue: #114a69;
$light: #f3efe0;
$pageWidth: 1200px;

$firstSectionFloatDivHeight: 370px;
