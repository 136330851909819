@import "../../variables.scss";
.navbarComponent {
	position: absolute;
	top: 0px;
	left: 0px;
	height: $navbar-height;
	z-index: 999;
	width: 100vw;
	transition-property: background, height;
	transition-timing-function: ease-in-out;
	transition-duration: 0.25s;
	height: 60px;
	&.scrolled {
		background-color: #fff;
		height: 50px;
	}
	&-main {
		width: $pageWidth;
		height: 100%;
		margin: 0 auto;
		background-color: #fff;
		&-content {
			height: 100%;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			gap: 50px;
			&-logo {
				height: 50px;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				> img {
					height: 100%;
					margin-left: 100px;
				}
			}
			&-linkToDiv {
				height: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				gap: 5px;
				margin-right: 110px;
				&-item {
					display: flex;
					justify-content: center;
					align-items: center;
					height: 100%;
					padding: 0 10px;
					transition: 0.25s;
					border-top: 2px solid transparent;
					position: relative;
					color: $gray;
					font-weight: bold;
					user-select: none;
					> div.bar {
						display: flex;
						justify-content: center;
						align-items: center;
						position: absolute;
						height: 100%;
						right: -2px;
					}
					&:hover {
						cursor: pointer;
						border-top: 2px solid $gray;
					}
				}
			}
		}
	}
	&-mobile {
		position: fixed;
		width: 0px;
		height: 100vh;
		right: 0px;
		top: 0px;
		&.show {
			width: 100vw;
			visibility: visible;
			.navbarComponent-mobile-links {
				width: 200px;
				visibility: visible;
			}
		}
		&-closeDiv {
			position: absolute;
			width: 100vw;
			height: 100vh;
			z-index: 1;
		}
		&-links {
			position: absolute;
			right: 0px;
			top: 0px;
			height: 100%;
			z-index: 2;
			background-color: $medium-blue;
			width: 0px;
			transition-property: width, visibility;
			transition-timing-function: ease-in-out;
			transition-duration: 0.25s;
			visibility: hidden;
			&-wrapper {
				margin: 60px 15px 0px 15px;
				display: flex;
				flex-direction: column;
				gap: 15px;
				&-item {
					color: #fff;
					border-bottom: 1px solid #fff;
					height: 35px;
					transition: color ease-in-out 0.25s;
					&:hover {
						color: #777;
						cursor: pointer;
					}
				}
			}
		}
	}
}

@media (max-width: 1200px) {
	.navbarComponent {
		&-main {
			width: 960px;
		}
	}
}

@media (max-width: 960px) {
	.navbarComponent {
		&-main {
			width: 100vw;
			margin: 0px;
			padding: 0 2vw;
			padding-right: 5vw;
			&-content {
				&-logo {
					> img {
						margin-left: 0px;
					}
				}
			}
		}
	}
}

@media (max-width: 768px) {
	.navbarComponent {
		&-main {
			&-content {
				justify-content: space-between;
				&-linkToDiv {
					&-item {
						padding: 0px 5px;
					}
				}
			}
		}
	}
}
