@import "../../variables.scss";
.fifthSection {
	position: relative;
	&-floater {
		position: absolute;
		top: -45px;
		left: 100px;
		width: 350px;
		height: 420px;
		background-color: $dark-blue;
		&-title {
			margin-top: 45px;
			display: flex;
			flex-direction: row;
			align-items: center;
			> div {
				top: 50%;
				left: 0px;
				width: 75px;
				height: 0px;
				border: 1px solid #fff;
				margin-right: 10px;
			}
			> p {
				color: #fff;
				font-weight: bold;
				font-size: 18px;
			}
		}
		&-address,
		&-email,
		&-cellphone {
			padding-left: 75px;
			margin-top: 40px;
			> p {
				color: #fff;
			}
		}
		&-address {
			> p {
				&:not(:last-of-type) {
					margin-bottom: 5px;
				}
			}
		}
	}
	&-map {
		height: 350px;
		overflow: hidden;
		> iframe {
			outline: none;
			border: none;
		}
		> img {
			width: 100%;
		}
	}
}

@media (max-width: 1200px) {
	.fifthSection {
		width: 100vw;
		display: grid;
		grid-template-columns: 0.4fr 1fr;
		grid-template-rows: 40vw;
		&-floater {
			position: relative;
			top: 0px;
			left: 0px;
			height: 100%;
			width: 100%;
			padding: 10px;
			&-address,
			&-email,
			&-cellphone {
				padding-left: 2vw;
			}
		}
		&-map {
			height: 100%;
		}
	}
}

@media (max-width: 960px) {
	.fifthSection {
		margin: 30px 0px;
		width: 100%;
		display: flex;
		flex-direction: column;
		&-floater {
			position: relative;
			height: auto;
			width: 100%;
			top: 0px;
			left: 0px;
			padding: 16px;
			&-title {
				margin-top: 0px;
				> div {
					width: 16px;
				}
				> p {
					font-size: 18px;
				}
			}
			&-address,
			&-cellphone,
			&-email {
				padding-left: 16px;
				margin-top: 30px;
				> p {
					font-size: 16px;
				}
			}
		}
		&-map {
			height: 220px;
		}
	}
}
