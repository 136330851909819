@import "../../variables.scss";
.firstSection {
	width: 100vw;
	height: 80vh;
	background-color: #fff;
	position: relative;
	grid-template-rows: 70vh 1fr;
	&-image {
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		z-index: 1;
		overflow: hidden;
		height: 65vh;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		background-color: #000;
		> img {
			width: 1400px;
		}
	}

	&-first {
		background-position: right;
		background-size: cover;
		background-repeat: no-repeat;
		background-color: #000;
		padding-top: $navbar-height;
		height: 100%;
		width: 100%;
		background-color: transparent;
		z-index: 10;
		min-height: 510px;
		&-content {
			margin: 0 auto;
			width: $pageWidth;
			padding-top: 10%;
			padding-left: 5%;
			> p {
				&:first-of-type {
					color: #fff;
					font-size: 46px;
					font-weight: 700;
				}
				&:last-of-type {
					margin-top: 15px;
					color: #fff;
					font-size: 22px;
				}
			}
		}
	}

	&-second {
		z-index: 10;
		width: 100%;
		height: $firstSectionFloatDivHeight;
		margin-top: calc((#{$firstSectionFloatDivHeight} / 2) * -1);
		&-main {
			background-color: $dark-blue;
			width: $pageWidth;
			height: 100%;
			margin: 0 auto;
			padding: 20px;
			&-title {
				display: flex;
				align-items: center;
				> div {
					display: inline-block;
					&:first-of-type {
						border: 1px solid #fff;
						height: 0px;
						width: 80px;
						margin-right: 10px;
					}
					&:last-of-type {
						color: #fff;
						font-weight: 600;
					}
				}
			}
			&-body {
				height: 100%;
				display: flex;
				gap: 150px;
				padding-left: 40px;
				> div {
					&:first-of-type {
						color: #fff;
						font-size: 30px;
						line-height: 34px;
						font-weight: 600;
						padding-top: 30px;
					}
					&:last-of-type {
						width: 700px;
						> p {
							font-size: 18px;
							color: #fff;
							&:not(:last-of-type) {
								margin-bottom: 15px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1366px) {
	.firstSection {
		&-first {
			height: 90vh;
		}
	}
}

@media (max-width: 1200px) {
	.firstSection {
		&-first {
			&-content {
				width: 960px;
				padding-left: 5vw;
			}
		}
		&-second {
			&-main {
				width: 960px;
				&-body {
					> div {
						&:last-of-type {
							> p {
								font-size: 16px;
							}
						}
					}
				}
			}
		}
	}
}

@media (max-width: 960px) {
	.firstSection {
		display: block;
		height: auto;
		&-first {
			width: 100%;
			background-position: left;
			height: 350px;
			min-height: initial;
			&-content {
				padding-top: 80px;
				padding-left: 32px;
				> p {
					&:first-of-type {
						font-size: 22px;
					}
					&:last-of-type {
						margin-top: 10px;
						font-size: 16px;
						width: 25%;
					}
				}
			}
		}
		&-second {
			position: relative;
			height: auto;
			bottom: 0px;
			margin-top: 0px;
			&-main {
				width: 100vw;
				height: auto;
				margin: 0px;
				padding: 16px;
				&-title {
					margin-bottom: 16px;
					> div {
						&:first-of-type {
							width: 16px;
						}
						&:last-of-type {
							> p {
								font-size: 18px;
							}
						}
					}
				}
				&-body {
					width: 100%;
					display: block;
					padding-left: 16px;
					gap: 0px;
					grid-gap: 0px;
					> div {
						&:first-of-type {
							font-size: 18px;
							line-height: initial;
							padding-top: 0px;
							width: 100%;
							margin-bottom: 12px;
						}
						&:last-of-type {
							width: 100%;
							> p {
								margin-bottom: 16px;
								font-size: 14px;
								line-height: initial;
								&:not(:last-of-type) {
									margin-bottom: 8px;
								}
							}
						}
					}
				}
			}
		}
	}
}

@media (max-height: 700px) {
	$firstSectionFloatDivHeight: 300px;
}
