.goToTopComponent {
	&.show {
		visibility: visible;
	}
	transition: visibility ease-in-out 0.5s;
	visibility: hidden;
	position: fixed;
	bottom: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 20px;
	background-color: #b1b1b1;
	color: #fff;
	z-index: 100;
	cursor: pointer;
}
