@import "../../variables.scss";
.menuBtn {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 30px;
	height: 30px;
	cursor: pointer;
	transition: all ease-in-out 0.25s;
	&.open {
		.menuBtn-burger {
			transform: translateX(-30px);
			background: transparent;
			box-shadow: none;
			&::before {
				transform: rotate(45deg) translate(25px, -25px);
			}
			&::after {
				transform: rotate(-45deg) translate(25px, 25px);
			}
		}
	}
	&-burger {
		width: 30px;
		height: 4px;
		background-color: $gray;
		border-radius: 5px;
		box-shadow: 0 2px 5px rgba(121, 121, 121, 0.2);
		transition: all ease-in-out 0.25s;
		&::before,
		&::after {
			content: "";
			position: absolute;
			width: 30px;
			height: 4px;
			background-color: $gray;
			border-radius: 5px;
			box-shadow: 0 2px 5px rgba(121, 121, 121, 0.2);
			transition: all ease-in-out 0.25s;
		}

		&::before {
			transform: translateY(-12px);
		}
		&::after {
			transform: translateY(12px);
		}
	}
}