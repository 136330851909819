@import "../../variables.scss";
.footerComponent {
	height: 200px;
	background-color: #dcdcdc;
	padding: 40px 140px 0px 100px;
	&-title {
		display: flex;
		flex-direction: row;
		align-items: center;
		> div {
			&:first-of-type {
				width: 75px;
				margin-right: 15px;
				height: 0px;
				border: 1px solid $dark-blue;
			}
		}
		> p {
			font-weight: bold;
			font-size: 18px;
			color: $dark-blue;
		}
	}
	&-main {
		display: flex;
		justify-content: space-between;
		&-leftSide {
			display: flex;
			flex-direction: row;
			gap: 20px;
			height: 60px;
			margin-top: 25px;
			> img {
				height: 100%;
				&:hover {
					cursor: pointer;
				}
			}
		}
		&-rightSide {
			> div {
				&:first-of-type {
					height: 50px;
					margin-bottom: 10px;
					> img {
						height: 100%;
						&:hover {
							cursor: pointer;
						}
					}
					> p {
						margin: 0 auto;
						width: min-content;
					}
				}
				&:last-of-type {
					display: flex;
					align-items: center;
					justify-content: center;
					gap: 30px;
					> a {
						text-decoration: none;
						font-size: 22px;
						color: $light-blue;
					}
				}
			}
		}
	}
}

@media (max-width: 960px) {
	.footerComponent {
		padding: 16px;
		height: auto;
		&-title {
			> div {
				&:first-of-type {
					width: 24px;
				}
			}
			> p {
				font-size: 18px;
			}
		}
		&-main {
			gap: 0px;
			margin-top: 12px;
			justify-content: space-between;
			&-leftSide {
				gap: 12px;
				margin-top: 0px;
				flex-direction: column;
				height: max-content;
				> img {
					height: 32px;
				}
			}
			&-rightSide {
				> div {
					&:first-of-type {
						height: 32px;
						margin-bottom: 16px;
					}
					&:last-of-type {
						gap: 16px;
						> a {
							> i {
								font-size: 18px;
							}
						}
					}
				}
			}
		}
	}
}
