@import "../../variables.scss";
.thirdSection {
	position: relative;
	padding-bottom: 80px;
	margin-top: 30px;
	&-leftSide {
		height: 450px;
		padding: 60px;
		padding-left: 100px;
		background-color: $dark-blue;
		width: 50vw;
		&-title {
			display: flex;
			align-items: center;
			> div {
				border: 1px solid #fff;
				width: 100px;
				margin-right: 10px;
			}
			> p {
				font-weight: bold;
				color: #fff;
				font-size: 22px;
			}
		}
		&-content {
			margin-top: 35px;
			width: 90%;
			> p {
				font-size: 18px;
				color: #fff;
				&:not(:first-of-type){
					margin-top: 10px;
				}
			}
		}
	}
	&-rightSide {
		position: absolute;
		top: 25px;
		left: calc(50% - 40px);
		height: 400px;
		width: 50vw;
		&-label {
			position: absolute;
			left: 0px;
			bottom: 0px;
			height: 70px;
			width: 220px;
			background-color: $dark-blue;
			display: flex;
			align-items: center;
			color: #fff;
			padding-left: 20px;
			z-index: 2;
			&:hover {
				cursor: pointer;
			}
			> p {
				font-size: 20px;
				margin-right: 20px;
			}
			> i {
				font-size: 36px;
			}
		}
	}
}

@media (max-width: 960px) {
	.thirdSection {
		padding-bottom: 0px;
		width: 100%;
		&-leftSide {
			width: 100%;
			padding: 16px;
			height: auto;
			&-title {
				> div {
					width: 16px;
				}
				> p {
					font-size: 18px;
				}
			}
			&-content {
				margin-top: 15px;
				> p {
					font-size: 14px;
					&:not(:last-of-type){
						margin-bottom: 12px;
					}
				}
			}
		}
		&-rightSide {
			position: relative;
			top: 0px;
			left: 0px;
			width: 100%;
			> label {
				display: none;
			}
		}
	}
}
