.casesComponent {
	margin-bottom: 60px;
	&-title {
		position: relative;
		padding-left: 100px;
		display: flex;
		align-items: center;
		user-select: none;
		> div {
			&:first-of-type {
				z-index: 1;
				> p {
					font-size: 92px;
					color: #f5f4f4;
				}
			}
			&:last-of-type {
				z-index: 2;
				position: absolute;
				top: calc(50% - 4px);
				display: flex;
				align-items: center;
				> div {
					width: 100px;
					margin-right: 10px;
					border: 1px solid #1a4c68;
				}
				> p {
					font-size: 24px;
					font-weight: bold;
					color: #1a4c68;
				}
			}
		}
	}
	&-body {
		position: relative;
		max-height: 430px;
		overflow: hidden;
		&.link {
			cursor: pointer;
		}
		&-text {
			position: absolute;
			top: 0px;
			left: 0px;
			color: #fff;
			top: 25%;
			left: 15%;
			font-size: 36px;
		}
		&-imagesWrapper {
			padding: 0px 70px 0px 100px;
			> img {
				height: 80px;
				&:not(:last-of-type) {
					margin-right: 10px;
				}
			}
		}
		> img {
			width: 100%;
			&.brightness {
				filter: brightness(0.3);
			}
		}
	}
}

@media (max-width: 960px) {
	.casesComponent {
		margin-top: 30px;
		&-title {
			padding-left: 16px;
			> div {
				&:first-of-type {
					> p {
						font-size: 40px;
					}
				}
				&:last-of-type {
					> div {
						width: 16px;
					}
					> p {
						font-size: 18px;
					}
				}
			}
		}
		&-body {
			max-height: max-content;
			&.link {
				> img {
					height: 40vw;
				}
			}
			&-text {
				top: 50px;
				left: 40px;
				font-size: 18px;
			}
			&-imagesWrapper {
				padding: 0px 16px;
				> img {
					height: 42px;
				}
			}
		}
	}
}

@media (max-width: 340px) {
	.casesComponent {
		&-title {
			> div {
				&:first-of-type {
					> p {
						font-size: 34px;
					}
				}
			}
		}
		&-body {
			&-text {
				top: 25px;
			}
		}
	}
}
